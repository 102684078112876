import { RouteRecordRaw } from 'vue-router';
import { Home } from '../views/Home';
import { Login } from '../views/Login';
import { Product, ProductDetail } from '../views/Product';
import { BankAccount, Wallet } from '../views/Wallet';
import { Order } from '../views/Order';
import { Mine } from '../views/Mine';
import { PaymentRecords } from '../views/PaymentRecords';
import { Payment } from '../views/Payment';
import { MyTeam, CommissionDetail } from '../views/MyTeam';
import { Faq } from '../views/Faq';
import { Share } from '../views/Share';
import { About } from '../views/About';

const { t } = useI18n();

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => import('layouts/NavigateLayout.vue'),
    redirect: '/home',
    name: 'NavigateLayout',
    meta: {},
    children: [
      {
        path: 'home',
        // component: () => import('@/views/Home'), // 多种选择的组件暂无法使用懒加载
        component: Home,
        name: 'Home',
        meta: {
          title: t('router.home'),
          icon: 'svg:footer_home', // 菜单图标
          activeIcon: 'svg:footer_home-active', // 菜单激活图标
          showMenu: true,
          sort: 1, // 菜单排序
          keepAlive: true
        }
      },
      // {
      //   path: 'product',
      //   component: Product,
      //   name: 'Product',
      //   meta: {
      //     title: t('router.product'),
      //     icon: 'svg:footer_product',
      //     activeIcon: 'svg:footer_product-active',
      //     showMenu: false,
      //     sort: 1,
      //     keepAlive: true
      //   }
      // },
      // {
      //   path: 'productDetail/:productId',
      //   component: ProductDetail,
      //   name: 'ProductDetail',
      //   props: (route) => ({ productId: route.params.productId }),
      //   meta: {
      //     title: t('router.productDetail'),
      //     // keepAlive: true
      //   }
      // },
      // {
      //   path: 'payment/:productId',
      //   component: Payment,
      //   name: 'Payment',
      //   props: (route) => ({ productId: route.params.productId }),
      //   meta: {
      //     title: t('router.payment'),
      //     // keepAlive: true
      //   }
      // },
      {
        path: 'wallet',
        component: Wallet,
        name: 'Wallet',
        meta: {
          title: t('router.wallet'),
          icon: 'svg:footer_wallet',
          activeIcon: 'svg:footer_wallet-active',
          // showMenu: true,
          // sort: 3,
          keepAlive: true
        }
      },
      {
        path: 'order',
        component: Order,
        name: 'Order',
        meta: {
          title: t('router.order'),
          icon: 'svg:footer_order',
          activeIcon: 'svg:footer_order-active',
          showMenu: true,
          sort: 2,
          keepAlive: true
        }
      },
      {
        path: 'paymentRecords',
        component: PaymentRecords,
        name: 'PaymentRecords',
        meta: {
          title: t('router.paymentRecords'),
          keepAlive: true
        }
      },
      {
        path: 'bankAccount',
        component: BankAccount,
        name: 'BankAccount',
        meta: {
          title: t('router.bankAccount'),
          // keepAlive: true
        }
      },
      {
        path: 'myTeam',
        component: MyTeam,
        name: 'MyTeam',
        meta: {
          title: t('router.myTeam'),
          icon: 'svg:footer_myTeam',
          activeIcon: 'svg:footer_myTeam-active',
          // showMenu: true,
          // sort: 4,
          keepAlive: true
        }
      },
      {
        path: 'commissionDetail/:level',
        component: CommissionDetail,
        props: (route) => ({ level: route.params.level }),
        name: 'CommissionDetail',
        meta: {
          title: t('router.myTeam'),
          keepAlive: true
        }
      },
      {
        path: 'faq',
        component: Faq,
        name: 'Faq',
        meta: {
          title: t('router.faq'),
          keepAlive: true
        }
      },
      {
        path: 'about',
        component: About,
        name: 'About',
        meta: {
          title: t('router.aboutUs'),
          keepAlive: true
        }
      },
      {
        path: 'share',
        component: Share,
        name: 'Share',
        meta: {
          title: t('router.share'),
          keepAlive: true
        }
      },
      {
        path: 'mine',
        component: Mine,
        name: 'Mine',
        meta: {
          title: t('router.mine'),
          icon: 'svg:footer_me',
          activeIcon: 'svg:footer_me-active',
          showMenu: true,
          sort: 5,
          keepAlive: true
        }
      }
    ]
  },
  {
    path: '/productDetail/:productId',
    component: ProductDetail,
    name: 'ProductDetail',
    props: (route) => ({ productId: route.params.productId }),
    meta: {
      title: t('router.productDetail'),
    }
  },
  {
    path: '/payment/:productId',
    component: Payment,
    name: 'Payment',
    props: (route) => ({ productId: route.params.productId }),
    meta: {
      title: t('router.payment'),
    }
  },
  {
    path: '/login',
    component: Login,
    name: 'Login',
    meta: {
      title: t('router.login'),
    }
  },


  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/ErrorNotFound.vue')
  }
];

export default routes;