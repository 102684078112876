<script setup lang="ts">

import { object } from 'vue-types';
import { Spu } from '@/api/finance/trading/product/spu';
import { processProductIncome } from '@/utils/product';
import { processPriceUnit } from '@/utils/price';

defineOptions({
  name: 'ProductItem'
});

const props = defineProps({
  item: object<Spu>().isRequired
});

const { t } = useI18n();

const spu = ref<Spu>(props.item);

const { push } = useRouter();

processProductIncome(spu.value);

</script>

<template>
  <q-card @click="push({name: 'ProductDetail', params: { productId: spu.id}})"
          class="col-5 row q-mb-md items-center radius-sm bg-product shadow-5 shadow-up-1 text-center"
  >
    <q-card-section class="col-12 q-pa-sm text-caption text-left" style="line-height: 1.2em">
      <div class="relative-position row">
        <div class="col"></div>
        <div class="col text-center relative-position">
          <div v-text="spu.name" class="q-pt-sm text-body1 text-weight-bold ellipsis" />
          <q-img :src="spu.picUrl" class="radius-sm" loading="lazy"
                 :ratio="1" fit="contain" height="140px"
          >
            <template #loading>
              <div class="text-primary">
                <q-spinner-ios />
                <!--            <div class="q-mt-md">Loading...</div>-->
              </div>
            </template>
          </q-img>
        </div>
        <div class="absolute-left q-pl-md q-py-sm text-body2 column items-start justify-between">
<!--          <div v-text="spu.name" class="text-subtitle2 text-weight-bold ellipsis" />-->
          <div
            class="ellipsis text-h6"
            v-text="`${t('product.price')} ${processPriceUnit(spu.price)}`"
          />
          <div
            class="ellipsis"
            v-text="`${t('product.dailyIncome')} ${processPriceUnit(spu.dailyIncome)}`"
          />
          <div
            class="ellipsis"
            v-text="`${t('product.totalIncome')} ${processPriceUnit(spu.totalIncome)}`"
          />
          <div
            class="ellipsis"
            v-text="`${t('product.serving')} ${processPriceUnit(spu.totalPeriod)} ${t('product.days')}`"
          />
<!--                 :label="processPriceUnit(spu.price)"-->
          <q-btn class="q-px-md q-py-xs bg-button" dense unelevated
                 :label="t('product.viewDetail')"
                 rounded noCaps
          />
        </div>
      </div>
    </q-card-section>
  </q-card>
</template>

<style lang="scss" scoped>

</style>