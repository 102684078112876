<script setup lang="ts">

defineOptions({
  name: 'UploadProof'
});

import { uploadFile } from '@/api/infra/file';
import { QLoading } from '@/utils/QLoading';
import { morph } from 'quasar';
import compressFile from '@/utils/compressFile';
import resizeImageFile from '@/utils/resizeImageFile';

const props = defineProps({
  defaultImg: {
    type: String
  },
  defaultDesc: {
    type: String
  },
  successFunc: {
    type: Function,
    required: true
  }
});

const emit = defineEmits(['success']);

const { successFunc, defaultImg, defaultDesc } = props;

const { t } = useI18n();

const file = ref<any>(null);
const imgUrl = ref();
const proofDesc = ref(defaultDesc);

// console.log(imgUrl.value);

const canUpload = computed(() => {
  return file.value != null;
});

const updateFile = async (newFile: File) => {
  if (!newFile) {
    return;
  }
  // console.log('原始大小', newFile.size);
  const compressedFile = await compressFile(newFile);
  // const resizedFile = await resizeImageFile(newFile);
  // console.log('压缩后大小', compressedFile.size);
  // console.log('resize后大小', resizedFile.size);
  // const comResizedFile = await compressFile(resizedFile);
  // console.log('压缩resize后大小', comResizedFile.size);
  // const resizeComedFile = await resizeImageFile(compressedFile);
  // console.log('resize压缩后大小', resizeComedFile.size);

  file.value = compressedFile;
  imgUrl.value = '';
  QLoading.show();
  try {
    const res = await upload();
    imgUrl.value = res.data;
  } finally {
    QLoading.hide();
  }
};

const upload = async () => {
  if (!canUpload.value) {
    return '';
  }
  return await uploadFile({ file: file.value });
};

const handleSuccess = async () => {
  await successFunc(imgUrl.value, proofDesc.value);
  emit('success');
};

const imgRef = ref();
const toggle = ref(false);
const defaultImgRef = ref();
const toggleDefault = ref(false);

let cancel1, cancel2;

const zoomImg = () => {
  const onToggle = () => {
    toggle.value = toggle.value !== true;
  };

  if (cancel1 === void 0 || cancel1() === false) {
    cancel1 = _morph(imgRef, onToggle);
  }
};
const zoomDefault = () => {
  const onToggle = () => {
    toggleDefault.value = toggleDefault.value !== true;
  };

  if (cancel2 === void 0 || cancel2() === false) {
    cancel2 = _morph(defaultImgRef, onToggle);
  }
};

const _morph = (ref, onToggle) => {
  return morph({
    from: ref.value.$el,
    onToggle,
    duration: 400,
    tween: true,
    tweenFromOpacity: 0.8,
    tweenToOpacity: 0.4,
    onEnd: end => {
      end === 'from' && onToggle();
    }
  });
};


</script>

<template>
  <div class="q-pa-none column items-center q-gutter-y-md">
    <div class="full-width">
      <div class="q-pa-sm text-weight-bold">{{ t('paymentRecord.uploadImg') }}</div>
      <q-file
        :model-value="file"
        @update:model-value="updateFile"
        outlined class="full-width"
        accept="image/*"
      >
        <template #file>
          <!--        <div>{{ 'proof image' }}</div>-->
          <!--              <q-img v-if="imgUrl" :src="imgUrl" style="max-height: 40vh"-->
          <!--                     :ratio="9/16" fit="contain" width="100%"  >-->
          <!--                <template #loading>-->
          <!--                  <div class="text-primary">-->
          <!--                    <q-spinner-ios />-->
          <!--                    <div class="q-mt-md">Loading...</div>-->
          <!--                  </div>-->
          <!--                </template>-->
          <!--              </q-img>-->
        </template>
      </q-file>
    </div>
    <div class="row full-width">
      <template v-if="imgUrl">
        <div class="col column items-center text-center">
          <div class="full-width ellipsis">{{ 'new proof image' }}</div>
          <q-img
            :src="imgUrl"
            ref="imgRef"
            :class="toggle ? 'fixed-top q-mt-xl q-mx-auto z-top' : void 0"
            style="border-radius: 3%/5%; flex: 0 0 10vw"
            :style="toggle ? 'width: 800px; max-width: 90vw;max-height: 90vh' : void 0"
            @click="zoomImg"
            ratio="9/16" fit="contain" height="85vh">
            <template #loading>
              <div class="text-primary">
                <q-spinner-ios />
                <div class="q-mt-md">Loading...</div>
              </div>
            </template>
          </q-img>
        </div>
      </template>

      <template v-if="defaultImg">
        <div class="col column items-center text-center">
          <div class="full-width ellipsis">{{ 'old proof image' }}</div>
          <q-img
            v-if="imgUrl != defaultImg"
            :src="defaultImg"
            ref="defaultImgRef"
            :class="toggleDefault ? 'fixed-top q-mt-xl q-mx-auto z-top' : void 0"
            style="border-radius: 3%/5%; flex: 0 0 10vw"
            :style="toggleDefault ? 'width: 800px; max-width: 90vw;max-height: 90vh' : void 0"
            @click="zoomDefault"
            ratio="9/16" fit="contain" height="85vh">
            <template #loading>
              <div class="text-primary">
                <q-spinner-ios />
                <div class="q-mt-md">Loading...</div>
              </div>
            </template>
          </q-img>
        </div>
      </template>
    </div>
    <!--凭证说明-->
    <div class="full-width">
      <div class="q-pa-sm text-weight-bold">{{ t('paymentRecord.uploadProofDesc') }}</div>
      <!--               standout="bg-grey-4 text-black" -->
      <q-input v-model="proofDesc" dense
               :placeholder="t('paymentRecord.uploadProofDescPlaceHolder')"
               outlined autogrow clearable
      >
      </q-input>
    </div>

    <!--确定按钮-->
    <q-btn no-caps rounded class="bg-primary full-width"
           @click="handleSuccess"
           :disable="!imgUrl && !proofDesc"
           :label="t('common.ok')" />
  </div>
</template>

<style scoped>

</style>