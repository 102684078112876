<script setup lang="ts">

import { useUserStore } from '@/stores/user';

defineOptions({
  name: 'BankCardBtn'
});

import BankCardForm from './BankCardForm.vue';

const { t } = useI18n();
const { push } = useRouter();

const { isBankNotOk } = toRefs(useUserStore())

const editCard = ref(false)

const handleClick = () => {
  // editCard.value = true
  push({ name: 'BankAccount' })
}

</script>

<template>
  <div class="row">
    <q-btn no-caps unelevated class="col full-height bg-blue-9" @click="handleClick">
      <q-badge v-if="isBankNotOk" floating rounded color="transparent" style="left: -5px">
        <q-icon name="info" size="1rem" color="orange" />
      </q-badge>

      <div class="q-pa-sm row items-center">
        <q-icon name="credit_card" size="2rem" class="col text-primary" />
        <div class="col q-pl-md" v-text="t('bank.card')" />
      </div>
    </q-btn>

    <q-dialog v-model="editCard" position="bottom" full-height>
      <q-card class=" relative-position">
        <q-card-section class="row justify-end q-pb-none">
          <div class="text-body2" v-text="t('bank.cardInfo')" />
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup/>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <BankCardForm @success="editCard = false"/>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<style scoped>

</style>