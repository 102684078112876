<script setup lang="ts">

import { useUserStore } from '@/stores/user';
import { processPriceUnit } from '@/utils/price';

const { t } = useI18n();

defineOptions({
  name: 'MyBalance'
});

// 从userStore获取id和余额
const { getMobile, getBalance } = toRefs(useUserStore());
const { updateBalance } = useUserStore();

const { push } = useRouter()

onActivated(() => {
  updateBalance();
});


</script>

<template>
  <div class="row no-wrap q-px-md q-py-md text-body1 text-weight-medium">
    <div class="col-12 row bg-balance radius-sm balance-box">

      <div class="col q-py-md q-px-xs row">
        <div class="col column q-pl-md">
          <!--          <div class="col">{{ `ID ${getMobile}` }}</div>-->
          <div class="col ellipsis" v-text="t('user.balance')"></div>
          <div v-text="processPriceUnit(getBalance)"
               class="col q-pb-xs text-h4 text-weight-bolder ellipsis" />
        </div>
        <div class="col-2 column justify-center">
          <q-btn
            class="q-pa-none"
            icon="chevron_right"
            size="xl"
            :ripple="false"
            round dense flat
            @click="push({ name: 'Wallet' })"
          />
        </div>
      </div>

    </div>
  </div>
</template>

<style scoped>
.balance-box {
  //background: linear-gradient(0deg, #265a97 20%, #102565 100%);
}
</style>