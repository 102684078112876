<script setup lang="ts">

import { useConfigStore } from '@/stores/manageConfig';

defineOptions({
  name: 'Me'
});

import { MyBalance } from '@/views/Common';
import {
  CommonItem,
  LogOutDialog
} from './components';

const { push } = useRouter();
const { t } = useI18n();

// region 定义各组件
const myTeam = {
  iconName: 'svg:mine_myTeam',
  label: t('mine.myTeam'),
  clickFn: () => push({ name: 'MyTeam' })
};

const faq = {
  iconName: 'svg:mine_FAQ',
  label: t('mine.faq'),
  clickFn: () => push({ name: 'Faq' })
};

const bankAccount = {
  iconName: 'svg:mine_bankCard',
  label: t('mine.bankAccount'),
  clickFn: () => push({ name: 'BankAccount' })
};

const wallet = {
  iconName: 'svg:mine_wallet',
  label: t('mine.wallet'),
  clickFn: () => push({ name: 'Wallet' })
};

const official = () => {
  const { toOfficialChannel } = useConfigStore();
  return {
    iconName: 'svg:mine_officialChannel',
    label: t('mine.official'),
    clickFn: toOfficialChannel
  };
};

const paymentRecords = {
  iconName: 'svg:mine_paymentRecord',
  label: t('mine.paymentRecord'),
  clickFn: () => push({ name: 'PaymentRecords' })
};

const referralLink = {
  iconName: 'svg:mine_referralLink',
  label: t('mine.referralLink'),
  clickFn: () => push({ name: 'Share' })
};

const aboutUs = {
  iconName: 'svg:mine_aboutUs',
  label: t('mine.aboutUs'),
  clickFn: () => push({ name: 'About' })
};

const logoutRef = ref();
const logOut = {
  iconName: 'svg:mine_logOut',
  label: t('mine.logOut'),
  clickFn: () => logoutRef.value.open()
};
// endregion

/** 组件列表 */
const itemList = [
  myTeam,
  bankAccount,
  wallet,
  faq,
  official(),
  paymentRecords,
  referralLink,
  aboutUs,
  logOut
];

</script>

<template>
  <q-page class="">
    <MyBalance />

    <q-list class="text-primary">
      <div v-for="(item, index) in itemList"
           :key="item.label">
        <CommonItem
          :iconName="item.iconName"
          :label="item.label"
          :clickFn="item.clickFn"
        />
        <q-separator v-if="index < itemList.length - 1" inset="item" />
      </div>
    </q-list>

    <LogOutDialog ref="logoutRef" />
  </q-page>
</template>

<style scoped>

</style>