<script setup lang="ts">
import { OrderItemVO } from '@/api/finance/trading/order';

defineOptions({
  name: 'ProductList'
});

import { ProductItem } from './index';
import { getSpuList, Spu } from '@/api/finance/trading/product/spu';
import OrderItem from '@/views/Order/src/components/OrderItem.vue';

const { t } = useI18n();
const productList = ref<Spu[]>([]);

const getProductList = async () => {
  productList.value = await getSpuList();
};

getProductList();

// onActivated(() => {
//   getProductList()
// })

const refresh = async (done) => {
  await getProductList();
  done();
};


</script>

<template>
  <!--  <q-card class="scroll q-px-xs q-pt-md col-grow row no-border-radius no-shadow product-list">-->
  <!--    &lt;!&ndash;    <q-scroll-area class="q-pt-sm no-border-radius"&ndash;&gt;-->
  <!--    &lt;!&ndash;                   :thumb-style="{opacity: '0'}"&ndash;&gt;-->
  <!--    &lt;!&ndash;                   :style="{ height: 'calc(100% - 1rpx)'}"&ndash;&gt;-->
  <!--    &lt;!&ndash;                   style="width: 100%">&ndash;&gt;-->
  <!--    <div class="q-px-md q-pb-sm">-->
  <!--      <div v-text="t('product.investmentPlans')" class="text-h5 text-weight-bold"></div>-->
  <!--      <div v-text="t('product.investmentPlansInfo')" class="text-caption"></div>-->
  <!--    </div>-->
  <!--    <div class="q-pa-xs row justify-around q-gutter-sm">-->
  <!--      <ProductItem v-for="product in productList"-->
  <!--                   :key="product.id"-->
  <!--                   :item="product"-->
  <!--      />-->
  <!--      &lt;!&ndash;        数量是奇数时需要占位&ndash;&gt;-->
  <!--      <div v-if="productList.length % 2 === 1" class="col-5" />-->
  <!--    </div>-->

  <!--    &lt;!&ndash;    </q-scroll-area>&ndash;&gt;-->
  <!--  </q-card>-->
  <q-list class="col-grow row product-list">
    <!-- 说明 -->
    <div class="q-px-md text-primary q-pb-sm">
      <div>
        <div v-text="t('product.howItWork')" class="text-h5 text-weight-bold"></div>
      </div>
      <div class="text-primary">
        <div v-text="t('product.howItWorkInfo1')" class="text-caption"></div>
        <div v-text="t('product.howItWorkInfo2')" class="text-caption"></div>
        <div v-text="t('product.howItWorkInfo3')" class="text-caption"></div>
        <div v-text="t('product.howItWorkInfo4')" class="text-caption"></div>
      </div>
    </div>
    <!-- 列表 -->
    <q-item class="full-width" v-for="product in productList" :key="product.id">
      <ProductItem class="full-width" :item="product" />
    </q-item>
  </q-list>
</template>

<style lang="scss" scoped>

</style>