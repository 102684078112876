<script setup lang="ts">


defineOptions({
  name: 'About'
});

import { MyBalance } from '@/views/Common';
import { CompanyIntro } from './components';

const { t } = useI18n()

const { push } = useRouter()


</script>

<template>
  <q-page class="column no-wrap">
<!--    <MyBalance />-->
    <!--描述-->
    <CompanyIntro />
    <div class="q-px-md other-btns">
      <div class="q-py-md column">
        <div></div>
        <q-btn class="q-py-sm bg-button text-h6 radius-sm"
               :label="t('mine.referralLink')"
               rounded noCaps unelevated
               @click="push({ name: 'Share' })"
        />
      </div>
    </div>
  </q-page>
</template>

<style scoped>

</style>