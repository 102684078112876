<script setup lang="ts">

import { processPriceUnit } from '@/utils/price';
import { useConfigStore } from '@/stores/manageConfig';

defineOptions({
  name: 'RecordDesc'
});

const { t } = useI18n();

const { getConfig } = toRefs(useConfigStore());
const minAmount = getConfig.value.minWithdrawNumYuan;

const isDark = import.meta.env.VITE_APP_DARK_MODE === 'true';

</script>

<template>
  <div class="radius-sm q-pa-md desc-box"
       :class="isDark ? 'desc-box-dark' : 'desc-box-light'"
  >
<!--    <div v-text="'• ' + t('withdraw.processTime') + ': 7x24h'"></div>-->
<!--    <div v-text="'• ' + t('withdraw.fees') + ': None'"></div>-->
<!--    <div v-text="'• ' + t('withdraw.minimumAmount') +': '+ processPriceUnit(minAmount)"></div>-->

    <div v-text="'• ' + t('withdraw.processTime') + ': 7x24h'"></div>
    <div v-text="'• ' + t('withdraw.minimumAmount') +': '+ processPriceUnit(minAmount)"></div>
    <div v-text="'• ' + t('withdraw.arrivalTime') + ': Within 24h'"></div>
    <div v-text="'• ' + t('withdraw.fees') + ': None'"></div>
  </div>
</template>

<style lang="scss" scoped>

</style>