<script setup lang="ts">
import { getSpuDetail, Spu } from '@/api/finance/trading/product/spu';
import { processProductIncome } from '@/utils/product';
import { QLoading } from '@/utils/QLoading';
import { processPriceUnit } from '@/utils/price';

defineOptions({
  name: 'ProductDetail'
});

const props = defineProps({
  productId: {
    type: String,
    required: true
  }
});

QLoading.show();

const { t } = useI18n();
const { push, replace, back } = useRouter();

const { productId } = props;
const productDetail = ref<Spu>({} as Spu);

const getProductDetail = async () => {
  try {
    productDetail.value = await getSpuDetail(productId as unknown as number);
    processProductIncome(productDetail.value);
  } catch (e) {
    await replace({ name: 'Home' });
    return;
  } finally {
    QLoading.hide();
  }
};

getProductDetail();

const imgList = computed(() => {
  const list = productDetail.value.sliderPicUrls;
  if (!list || list.length === 0) {
    return [productDetail.value.picUrl];
  }
  return [productDetail.value.picUrl, ...list];
});

const handleBuy = () => {
  push({ name: 'Payment', params: { productId } });
};

const isDark = import.meta.env.VITE_APP_DARK_MOD as boolean;

</script>

<template>
  <q-layout view="lHr LpR lFr" :class="isDark ? 'text-grey-1' : 'text-grey-9'">
    <q-page-container>
      <q-header class="bg-header column items-center">
        <q-bar class="bg-header q-pa-md q-py-lg text-weight-bold">
          <q-btn dense rounded flat icon="svg:back" @click="back"
                 class="absolute-left q-pl-md" :ripple="false"
          />
          <q-space />
          <div v-text="productDetail.name" />
          <q-space />
        </q-bar>
      </q-header>
      <q-page class="bg-common row column">
<!--        <q-bar class="bg-header q-pa-md q-py-lg q-py-md">-->
<!--&lt;!&ndash;          <q-btn dense rounded flat icon="svg:back" class="col" @click="back" />&ndash;&gt;-->
<!--          <q-btn dense rounded flat icon="svg:back" @click="back"-->
<!--                 class="absolute-left q-pl-md" :ripple="false"-->
<!--          />-->
<!--          <q-space />-->
<!--          <div v-text="productDetail.name" class="col-8 text-center text-wrap-keep text-weight-bold" />-->
<!--          <q-space />-->
<!--        </q-bar>-->
        <!--轮播图-->
        <q-responsive :ratio="7/5" class="q-pt-sm full-width">
          <ImgCarousel class="img-carousel" :src-list="imgList" />
        </q-responsive>
        <!--底部-->
        <q-card class="bg-header q-px-lg q-py-lg col-grow column radius-top-lg no-shadow detail-card">
          <div class="absolute-top">
            <div class="column q-pt-sm items-center">
              <q-separator style="width: 35px" color="primary" size="3px" />
            </div>
          </div>

          <q-card-section class="q-pa-none q-gutter-sm text-h6">
            <!--名称-->
<!--            <div class="row">-->
<!--              <div class="col-12 row">-->
<!--                <div class="col-5" v-text="productDetail.name" />-->
<!--              </div>-->
<!--            </div>-->
            <!--价钱-->
            <div class="row">
              <div class="col-12 row">
                <div class="col-5" v-text="t('product.price')" />
                <div class="col-7 text-right text-primary text-weight-bold ellipsis">
                  {{ processPriceUnit(productDetail.price) }}
                </div>
              </div>
              <div class="col-12 row">
                <div class="col-5" v-text="t('product.serving')" />
                <div class="col-7 text-right text-primary text-weight-bold ellipsis">
                  {{ productDetail.totalPeriod }}
                  {{ productDetail.totalPeriod > 1 ? t('product.days') : t('product.day') }}
                </div>
              </div>
            </div>
            <!--Daily income 和 Total income-->
            <div class="row">
              <div class="col-12 row">
                <div class="col-5" v-text="t('product.dailyIncome')" />
                <div class="col-7 text-right text-primary text-weight-bold ellipsis">
                  {{ processPriceUnit(productDetail.dailyIncome) }}
                  <span class="text-grey-5">|</span>
                  {{ productDetail.dailyReturnRate }}%
                </div>
              </div>

              <div class="col-12 row">
                <div class="col-5" v-text="t('product.totalIncome')" />
                <div class="col-7 text-right text-primary text-weight-bold ellipsis">
                  {{ processPriceUnit(productDetail.totalIncome) }}
                  <span class="text-grey-5">|</span>
                  {{ productDetail.totalReturnRate }}%
                </div>
              </div>
            </div>
          </q-card-section>

          <q-separator color="primary" spaced />
          <!--描述-->
          <q-card-section class="q-pa-none">
            <!--                    <p class="text-subtitle2 text-weight-bold no-margin">{{ productDetail.introduction }}</p>-->
            <!--                    <q-separator color="gray-5" spaced />-->
            <div v-html="productDetail.description" />
          </q-card-section>
        </q-card>

        <!-- 顶部 -->
        <!--        <q-page-sticky expand position="top">-->
        <!--          <q-toolbar class="bg-transparent q-py-md text-h6">-->
        <!--            <q-btn dense rounded flat icon="svg:back" class="col" @click="back" />-->
        <!--            <div v-text="productDetail.name" class="col-8 text-common text-center text-wrap-keep text-weight-bold" />-->
        <!--            <div class="col"></div>-->
        <!--          </q-toolbar>-->
        <!--        </q-page-sticky>-->

        <!-- 底部按钮 -->
        <q-page-sticky expand position="bottom">
          <q-btn class="fit q-ma-md q-pa-sm radius-sm full-height"
                 no-caps color="button"
                 @click="handleBuy">
            <div class="row justify-center">
              <div v-text="t('product.placeAnOrder')" class="col text-subtitle1" />
              <!--                <div v-text="processPriceUnit(productDetail.price)"-->
              <!--                     class="text-subtitle1 text-weight-bold" />-->
            </div>
          </q-btn>
        </q-page-sticky>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<style lang="scss" scoped>
.detail-card {
  margin-top: -2rem;
  display: inline-block;
  border-top: 1px solid $primary;
}

.img-carousel {
  :deep(.q-carousel__navigation .q-btn) {
    opacity: 0;
  }
}

</style>