<script setup lang="ts">

import { useUserStore } from '@/stores/user';

defineOptions({
  name: 'Share'
});

import { QNotify } from '@/utils/QNotify';
import { decode62ToAscii3 } from '@/utils/string62Converter';
import { copyToClipboard } from 'quasar';

const { t } = useI18n();

const { getId, getId62 } = toRefs(useUserStore());
const { loginOut } = useUserStore();
const { currentRoute, replace } = useRouter();

const affiliate = currentRoute.value?.query?.join?.toString();

const toLogin = async (code: string) => {
  await replace({
    path: '/login',
    query: {
      code
    }
  });
};

const handleLogOut = async (code: string) => {
  try {
    await loginOut();
    await toLogin(code);
  } catch {
  }
};

const parseAffiliateToId = (affiliate: string): string => {
  if (!affiliate) return '';
  const decode = decode62ToAscii3(affiliate);
  if (!decode) return '';
  const split = decode.split('!');
  if (split.length < 2) return '';
  return split[1];
};

if (affiliate) {
  // 从推广链接进入的
  if (!getId.value || getId.value === 0) {
    // 未登录
    toLogin(affiliate);
  } else if (Number(parseAffiliateToId(affiliate)) != getId.value) {
    // 已登录但点了别人的链接
    handleLogOut(affiliate);
  }
}

const shareLink = ref(`${window.location.origin}/#/share?join=${getId62.value}`);

/** 复制 **/
const copy = async (text: string) => {
  // const { copy, copied, isSupported } = useClipboard({ source: text })
  // if (!isSupported.value) {
  //   QNotify.error(t('common.copyError'))
  //   return
  // }
  // await copy()
  // if (unref(copied)) {
  //   QNotify.success(t('common.copySuccess'))
  // }
  copyToClipboard(text)
    .then(() => {
      // 成功!
      QNotify.success(t('common.copySuccess'));
    })
    .catch(() => {
      // 失败
      QNotify.error(t('common.copyError'));
    });
};

const isDark = import.meta.env.VITE_APP_DARK_MODE === 'true';

</script>

<template>
  <q-page class="column">
    <!--轮播图-->
    <CommonCarousel type="share" :ratio="16/9"/>

    <!--推广链接 和 材料-->
    <div class="q-pa-md q-gutter-md">
      <!--推广链接-->
      <div>
        <div class="row items-center">
          <div class="q-pr-sm">
            <q-icon color="primary" name="svg:mine_referralLink" size="sm" />
          </div>
          <div class="text-primary text-subtitle1 text-weight-bold">{{ t('share.link') }}</div>
        </div>
        <q-input
          v-model="shareLink"
          outlined rounded dense readonly :dark="isDark"
          class="text-no-wrap ellipsis"
        >
          <template v-slot:append>
            <q-btn
              rounded no-caps unelevated dense
              class="q-py-none q-px-md text-caption"
              color="primary"
              :label="t('common.copy')"
              @click="copy(shareLink)"
            />
          </template>
        </q-input>
      </div>


      <!--推广材料-->
<!--      <q-card class="radius-sm no-shadow">-->
<!--        <q-card-section class="q-px-md q-py-xs row items-center">-->
<!--          <div class="col-9">-->
<!--            <div class="text-body1 text-weight-bold">-->
<!--              {{ t('share.promotionMaterial') }}-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="col text-right">-->
<!--            <q-btn-->
<!--              rounded no-caps unelevated-->
<!--              class="q-px-lg q-py-xs text-caption"-->
<!--              color="primary"-->
<!--              :label="t('common.copy')"-->
<!--              @click="copy(t('share.promotionContent') + ': ' + shareLink)"-->
<!--            />-->
<!--          </div>-->
<!--        </q-card-section>-->
<!--        <q-separator />-->
<!--        <q-card-section>-->
<!--          {{ t('share.promotionContent') }}-->
<!--        </q-card-section>-->
<!--      </q-card>-->
    </div>
  </q-page>
</template>

<style scoped>

</style>