<script setup lang="ts">

defineOptions({
  name: 'RecordDesc'
})

const { t } = useI18n()

const isDark = import.meta.env.VITE_APP_DARK_MODE === 'true';

</script>

<template>
<div class="radius-sm q-pa-md"
     :class="isDark ? 'desc-box-dark' : 'desc-box-light'"
     v-text="t('bank.remark')" />
</template>

<style lang="scss" scoped>

</style>