<script setup lang="ts">

import { QNotify } from '@/utils/QNotify';

defineOptions({
  name: 'Payment'
});

import { MyBalance } from '@/views/Common';
import { ChannelVO } from '@/api/finance/trading/pay/channel';
import { fenToYuan, processPriceUnit } from '@/utils/price';
import { createOrder, settlementOrder } from '@/api/finance/trading/order';
import { submitOrder } from '@/api/finance/trading/pay/order';
import { QLoading } from '@/utils/QLoading';
import { useChannelStore } from '@/stores/channel';

interface settlementVO {
  productName: string;
  totalPrice: number;
}

const props = defineProps({
  productId: {
    type: String,
    required: true
  }
});

const { t } = useI18n();
const { back, replace } = useRouter();

const { productId } = props;
const settlementInfo = ref<settlementVO>({} as settlementVO);

const isDark = import.meta.env.VITE_APP_DARK_MODE === 'true';

const getSettlementInfo = async () => {
  try {
    settlementInfo.value = await settlementOrder(productId as unknown as number);
  } catch (e) {
    await replace({ name: 'Product' });
    return;
  }
  settlementInfo.value.totalPrice = fenToYuan(settlementInfo.value.totalPrice);
};

getSettlementInfo();

// region 查询Channel

const { getChannelList } = toRefs(useChannelStore());
const channelCode = ref('');
const channelList = ref<ChannelVO[]>(getChannelList.value);

// endregion 查询Channel

const handlePay = async () => {
    console.log('pay!');
    console.log(channelCode.value);
    if (!channelCode.value) {
      QNotify.warning(t('payment.chooseMethodError'));
      return;
    }
    QLoading.show({
      message: t('common.processing')
    });
    try {
      // 1. 下单（tradeOrder）
      const order: { id: number, payOrderId: number } = await createOrder({ productId });
      if (!order) {
        QNotify.error(t('payment.payFailed'));
        return;
      }
      // 2. 根据返回的payOrderId 和 channelCode 进行支付，将Order页面作为returnUrl
      const payOrder: {
        status: number,
        displayMode: string,
        displayContent: string
      } = await submitOrder({
        id: order.payOrderId,
        channelCode: channelCode.value,
        returnUrl: `${window.location.origin}/#/order`
      });
      // 3. 根据返回的status判断是否要跳转到外部页面进行支付
      const status = payOrder.status;

      switch (status) {
        case 0: // 未支付
          // 跳转到外部页面进行支付
          window.location.href = payOrder.displayContent;
          break;
        case 10: // 支付成功
          // 跳转order页面
          await replace({ name: 'Order' });
          QNotify.success(t('payment.paySuccess'));
          break;
        default:
          await replace({ name: 'PaymentRecords' });
          QNotify.error(t('payment.payFailed'));
      }
      // 4. 支付成功后会自动跳转到Order页面
    } finally {
      // 完成
      QLoading.hide();
    }
  }
;

</script>

<template>
  <q-layout view="lHr LpR lFr" :class="isDark ? 'whiteText' : 'darkText'">
    <q-page-container>
      <q-header class="bg-header column items-center">
        <q-bar class="bg-header q-pa-md q-py-lg text-weight-bold">
          <q-btn dense rounded flat icon="svg:back" @click="back"
                 class="absolute-left q-pl-md" :ripple="false"
          />
          <q-space />
          <div v-text="t('router.payment')" />
          <q-space />
        </q-bar>
      </q-header>
      <q-page class="bg-common column no-wrap">
        <!--        <MyBalance />-->
        <div class="q-mt-xl q-py-xl text-center text-h3">
          {{ processPriceUnit(settlementInfo.totalPrice || 0) }}
        </div>
        <!-- 名称-->
        <div class="q-pa-md">
          <div class="q-py-xs row text-body1 text-weight-medium">
            <div class="col-4">{{ t('payment.productName') }}</div>
            <div class="col-8 text-right">{{ settlementInfo.productName }}</div>
          </div>
        </div>
        <!---->
        <q-card class="bg-header q-px-lg q-py-lg col-grow column radius-top-lg no-shadow payment-box">
          <div class="absolute-top">
            <div class="column q-pt-sm items-center">
              <q-separator style="width: 35px" color="primary" size="3px" />
            </div>
          </div>

          <q-card-section class="no-margin no-padding">
            <q-card-section class="q-px-sm q-py-xs text-body1 text-weight-bold">
              {{ t('payment.chooseMethod') }}
            </q-card-section>
            <q-separator color="primary" spaced />
            <q-list>
              <template v-for="(channel, index) in channelList"
                        :key="channel.code">
                <q-item tag="label" v-ripple class="q-px-sm">
                  <q-item-section>
                    <q-item-label>{{ channel.name }}</q-item-label>
                  </q-item-section>
                  <q-item-section avatar>
                    <q-radio v-model="channelCode" :val="channel.code"
                             color="primary" size="lg" :dark="isDark"
                             checked-icon="task_alt" unchecked-icon="panorama_fish_eye"
                    />
                  </q-item-section>
                </q-item>
                <q-separator v-if="index < channelList.length-1" inset />
              </template>
            </q-list>
          </q-card-section>
          <!--          <q-space />-->
          <!--          <q-separator spaced />-->
          <!--          <div class="column">-->
          <!--            <q-btn no-caps rounded class="bg-primary text-white"-->
          <!--                   :label="t('payment.pay')" @click="handlePay" />-->
          <!--          </div>-->
        </q-card>

        <!-- 底部按钮 -->
        <q-page-sticky expand position="bottom">
          <q-btn no-caps unelevated color="button"
                 class="fit q-ma-md q-pa-sm radius-sm full-height" @click="handlePay">
            <div class="row justify-center">
              <div v-text="t('payment.pay')" class="col text-subtitle1" />
              <!--                <div v-text="processPriceUnit(productDetail.price)"-->
              <!--                     class="text-subtitle1 text-weight-bold" />-->
            </div>
          </q-btn>
        </q-page-sticky>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<style lang="scss" scoped>

.payment-box {
  display: inline-block;
  border-top: 1px solid $primary;
  //border-left: 1px solid $primary;
  //border-right: 1px solid $primary;
}

</style>