<script setup lang="ts">

defineOptions({
  name: 'MyCommission'
})

type CommissionSummary = {
  totalCommission: number;
  todayCommission: number;
}

import { getPromoteIncomeSummary } from '@/api/finance/trading/pay/wallet/transaction';
import { fenToYuan, processPriceUnit } from '@/utils/price';

const { t } = useI18n();
const { push } = useRouter();

const totalCommission = ref(0);
const todayCommission = ref(0);

const getCommission = async () => {
  const res: CommissionSummary = await getPromoteIncomeSummary(0);
  totalCommission.value = fenToYuan(res.totalCommission);
  todayCommission.value = fenToYuan(res.todayCommission);
};

onActivated(() => {
  getCommission();
})


</script>

<template>
<div class="row text-center text-caption text-weight-medium">
  <div class="col-12 row q-pr-xs radius-sm bg-income">
    <div class="col q-py-md q-px-sm row justify-center ">
      <div v-text="processPriceUnit(totalCommission)"
           class="col-12 text-h6 text-primary text-weight-bolder ellipsis" />
      <div v-text="t('user.totalCommission')" class="ellipsis"></div>
    </div>
    <q-separator color="primary" vertical inset spaced/>
    <div class="col q-py-md q-px-sm row justify-center items-center ">
      <div v-text="processPriceUnit(todayCommission)"
           class="col-12 text-h6 text-primary text-weight-bolder ellipsis" />
      <div v-text="t('user.todayCommission')" class="ellipsis"></div>
    </div>
  </div>
</div>
</template>

<style scoped>

</style>